@charset "UTF-8";

@keyframes productheroslidein {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
