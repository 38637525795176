@charset "UTF-8";
section#contact {
  padding: 50px 5% 100px 5%;
  background-color:#F1F1F1;
  p {
    margin-bottom:20px;
  }
  a {
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    color: color(primary);
    padding: 10px 0;
    &:hover {
      text-decoration: underline;
    }
  }
  p.small {
    margin-top:50px;
    font-size:0.8rem;
    color:rgba(0,0,0,0.7);
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  section#contact {
    padding: 100px 10% 100px 10%;
    p.small {
      margin-top:100px;
    }
  }
}

@include breakpoint(desktop) {
  section#contact {
    padding: 150px 10% 150px 10%;
    p.small {
      margin-top:150px;
    }
  }
}

@include breakpoint(cinemadisplay) {
}
