@charset "UTF-8";
#top {
  .desktop-only {
    display: none;
  }
}
nav {
  .mobile-only {
    display: block;
  }
}
#top {
  color: color(white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 5%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: space-between;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 8;
  //background-color: rgba(47, 59, 85, 0);
  background: rgba(15, 19, 27, 0);
  .logo {
    font-weight: 700;
  }
  .contact-info {
    display: none;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: -100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 8;
  transition: 0.5s background cubic-bezier(0.19, 1, 0.22, 1) 0.1s, 0s top linear 0.5s;
}

.nav-visible {
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    transition: 0.5s background cubic-bezier(0.19, 1, 0.22, 1) 0.1s, 0s top linear 0s;
  }
}

#toggle-menu {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  //background-color: color(primary);
  position: absolute;
  top: 0;
  right: 5vw;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.3s, transform 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0s;

  &:before,
  &:after {
    content: "";
    background-color: color(white);
    width: 34%;
    height: 2px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    display: block;
    position: absolute;
    top: 50%;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:before {
    transform: translate3d(-50%, -150%, 0) rotate(0deg);
  }

  &:after {
    width: 25%;
    transform: translate3d(-68%, 150%, 0) rotate(0deg);
  }
}

nav {
  width: 280px;
  max-width: 280px;
  position: fixed;
  height: 100vh;
  right: -280px;
  top: 0;
  z-index: 9;

  .menu-inner {
    background-color: color(primary);
    opacity: 0;
    height: 100%;
    display: flex;
    align-items: center;
    transform: translate3d(0, 0, 0);
  }
  ul {
    position: relative;
    z-index: 11;
    li {
      a {
        padding: 20px 35px;
        display: block;
        color: color(white);
        opacity: 0;
        font-size: 1rem;
        line-height: 1.4;
        transform: translate3d(100%, 0, 0);
        transition: opacity 0.8s, transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        &:hover {
          transform: translate3d(5%, 0, 0) !important;
        }
      }
    }
  }
  //&:hover,
  &.visible {
    ul {
      li {
        a {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        &:nth-child(1) a {
          transition-delay: 0.03s;
        }
        &:nth-child(2) a {
          transition-delay: 0.06s;
        }
        &:nth-child(3) a {
          transition-delay: 0.09s;
        }
        &:nth-child(4) a {
          transition-delay: 0.12s;
        }
        &:nth-child(5) a {
          transition-delay: 0.15s;
        }
        &:nth-child(6) a {
          transition-delay: 0.18s;
        }
      }
    }
  }
  &.visible {
    .menu-inner {
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
      transition: opacity 0.3s, transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    }

    #toggle-menu {
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s, transform 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
      &:before {
        width: 40%;
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }

      &:after {
        width: 40%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }
    }
  }
}

#toggle-menu-wrapper {
  width: 50px;
  position: absolute;
  top: 15px;
  height: 50px;
  right: 280px;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.header-not-visible {
  #top {
    background: rgba(15, 19, 27, 1);
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  #top {
    .desktop-only {
      display: inline-block;
    }
  }
  nav {
    .mobile-only {
      display: none;
    }
  }
  /*#toggle-menu-wrapper {
    height: 80vh;
    top: 0;
    width: 100%;
    pointer-events: none;
  }*/

  nav {
    width: 340px;
    max-width: 340px;
    right: -340px;

    ul {
      li {
        a {
          padding: 20px 50px;
        }
      }
    }
  }
  #toggle-menu-wrapper {
    right: 340px;
    top: 22px;
  }
  .nav-visible {
    nav.visible {
      #toggle-menu {
        transform: translate3d(-195px, 0, 0);
        transition: opacity 0.3s, transform 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
      }
    }
  }
  #top {
    transform: translate3d(0, 0, 0);

    .contact-info {
      display: block;
      padding-right: 80px;
      a {
        color: inherit;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        &.blog-button {
          display: inline-block;
          background: color(primary);
          border-radius: 4px;
          padding: 8px 12px;
        }
      }
    }
  }

  #toggle-menu {
    &:hover {
      &:after {
        transform: translate3d(-38%, 150%, 0) rotate(0deg);
      }
      cursor: pointer;
    }
  }
  /*#toggle-menu-wrapper {
    transform: translate3d(-100%, 0, 0);
  }*/

  /*#toggle-menu {
    width: 60px;
    height: 60px;
    top: 40px;
    transform: translate3d(50%, -50%, 0);
  }
  nav {
    transform: translate3d(100%, 0, 0);
    .menu-inner {
      transform: translate3d(0, 0, 0);
    }
    &:hover {
      #toggle-menu {
        transform: translate3d(200%, -50%, 0);
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        opacity: 0;
      }
    }
  }*/

  .aanpak {
    #top {
      transform: translate3d(0, -100%, 0);
    }
  }

  .aanpak-hide {
    #top {
      transform: translate3d(0, 0, 0);
    }
  }
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
}
