@charset "UTF-8";
section#klanten {
  padding: 50px 5%;
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  .client {
    width: 33.33%;
    .image {
      img {

      }
    }
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  section#klanten {
    padding: 100px 5%;
    margin-top: 0;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    .client {
      width:20%;
      .image {
        img {
  
        }
      }
    }
  }
  
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
}
