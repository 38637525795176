@charset "UTF-8";
header {
  width: 100%;
  height: 100vh;
  background-image: url("../img/header-desktop.jpg");
  background-size: cover;
  background-position: 70% bottom;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 5vh;
  padding: 75px 5%;
  color: color(white);
  
  p {
    margin-bottom:5px;
  }
  .scroll-hint {
    position: absolute;
    left: 5%;
    display:none;
    bottom: 0;
    transform: translate3d(0, 50%, 0);
  }
}

.scroll-hint {
  display: none;
  width: 50px;
  height: 50px;
  background-color: color(black);
  border-radius: 50%;
  position: absolute;
  .arrow {
    background: #fff;
    height: 2px;
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(90deg);
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      background: #fff;
      position: absolute;
      height: 100%;
      width: 13px;
    }

    &:before {
      right: -3px;
      bottom: -4px;
      transform: rotate(-45deg);
    }

    &:after {
      right: -3px;
      bottom: 4px;
      transform: rotate(45deg);
    }
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  header {
    background-image: url("../img/header-desktop.jpg");
    background-position: 55% bottom;
    background-size: auto 130%;
    justify-content: center;
    padding: 80px 5%;
    height: 90vh;
    margin-bottom: 10vh;
    h1,
    p {
      padding-left: 100px;
    }

    h1 {
      border-left: 4px solid color(primary);
      padding-bottom: 0;
      padding-top: 0;
    }

    p {
      max-width: 600px;
    }

    .scroll-hint {
      left: calc(5% + 100px);
      display:block;
    }
  }

  .scroll-hint {
    width: 60px;
    display:block;

    height: 60px;
    .arrow {
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
    }
    &:hover {
      .arrow {
        top:55%;
      }
    }
  }
}

@include breakpoint(desktop) {
  header {
    background-size: auto 140%;
  }
}

@include breakpoint(cinemadisplay) {
  header {
    background-size: auto 150%;
  }
}
