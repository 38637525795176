@charset "UTF-8";

*,
*:before,
*:after {
  box: {
    sizing: inherit;
  }
  font: {
    smoothing: inherit;
  }
  outline: none;
}

html,
body {
  width: 100%;
  //cursor: none;
}

@supports (-webkit-overflow-scrolling: touch) {
  // disable overflow-x on mobile devices, but don't set this on non-touch devices since this generates an extra scrollbar
  html {
    overflow-x: hidden;
  }
}

html {
  box: {
    sizing: border-box;
  }
}

html {
  font {
    size: 13px;
  }
}
body {
  position: relative;
  height: 100%;
  //max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  text: {
    rendering: optimizeLegibility;
    size-adjust: 100%;
  }
  font: {
    family: font(body);
    smoothing: antialiased;
    size: 15px;
    weight: 400;
  }

  hyphens: auto; /* https://twitter.com/pugson/status/1034889153849307141 */
  -webkit-touch-callout: none;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* prevents horizontal scrollbars */
}

::focus:not(:focus-visible) {
  /* https://twitter.com/LeaVerou/status/1045768279753666562 */
  outline: none;
}

::selection {
  background: color(black);
  color: color(white);
}

h1,
h2,
h3 {
  hyphens: manual;
  width: 100%; // to prevent flexbox' magic
  font: {
    weight: 700;
    family: font(heading);
  }
  //color:color(green-400);
}

h4,
h5,
h6 {
  hyphens: manual;
  width: 100%; // to prevent flexbox' magic
  font: {
    family: font(heading);
    weight: 700;
  }
}

.wrapper-main {
  &.homepage {
    margin-top: 0;
  }
}

h1 {
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  .small {
    font-size: 0.8rem;
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  .small {
    font-size: 0.8rem;
  }
}

p {
  font-size: 1rem;
  line-height: 1.4rem;
}

@include breakpoint(tablet-portrait) {
  html {
    font: {
      size: 18px;
    }
  }
}

@include breakpoint(tablet-landscape) {
  html {
    font: {
      size: 20px;
    }
  }

  h1 {
    font-size:3rem;
    line-height:3rem;
  }
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
  html {
    font: {
      size: 22px;
    }
  }
}

a,
a:link,
a:visited,
a:active {
  text: {
    decoration: none;
  }
}

p {
  hyphens: manual;
  margin: {
    bottom: 1.5em;
    top: 0;
  }
  line-height: 1.6em;
  letter-spacing: 0.5px;
  &:last-of-type {
    margin: {
      bottom: 0;
    }
  }
}

.ak-block-normalpagehero,
.ak-block-text {
  ul {
    margin: {
      bottom: 2.5em;
      top: 0;
    }
    li {
      line-height: 1.6em;
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 1em;
    }
  }
  table {
    margin: {
      bottom: 2.5em;
      top: 0;
    }
    border: 1px solid rgba(3, 18, 32, 0.05);

    th,
    td {
      padding: 0.8em;
      border: 1px solid rgba(3, 18, 32, 0.05);
    }
    tbody {
    }
  }
}

em {
  font: {
    style: italic;
  }
}

strong {
  font: {
    weight: bold;
  }
}

img,
video {
  /* you want these elements fluid, probably... */
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

code {
  font: {
    family: Monaco;
    size: 10px;
  }
  outline: none;
}

@include browserfix(ie) {
  * {
    text: {
      shadow: 1px 1px 1px rgba(color(black), 0.08);
    }
    font: {
      smoothing: always;
    }
  }
}

.apb-element {
  position: relative;
}

.apb-admin {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;

  &.apb-admin-page {
    position: fixed;
    bottom: 30px;
    top: auto;
    right: auto;
    left: 30px;
    z-index: 501;
  }

  a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    background-color: #000;
    color: #fff !important;
    padding: 10px;
    ul {
      display: none;
    }
    &.fold {
      position: relative;
      &:hover {
        ul {
          display: block;
        }
      }
    }
  }
}

.apb-overlay {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 501;
  transition: opacity 0.3s ease 0.3s;
  opacity: 0;
  &.visible {
    top: 0;
    opacity: 1;
    transition: opacity 0.3s ease 0s;
  }
}

.apb-add-elements {
  position: fixed;
  left: 50%;
  max-width: 100%;
  width: 300px;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 30px;
  z-index: 502;
  opacity: 0;
  top: -100%;
  transition: opacity 0.3s ease 0.3s;
  a {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  &.visible {
    top: 50%;
    opacity: 1;
    transition: opacity 0.3s ease 0s;
  }
}

.user-login-form {
  width: 400px;
  max-width: 100%;
  padding-top: 150px;
  margin: 0 auto;

  label {
    family: font(body);
    font-size: 15px;
    letter-spacing: 0.5px;
    padding-bottom: 10px;
    display: block;
  }

  input.form-text {
    background-color: #efefef;
    padding: 21px 20px;
    display: block;
    border: none;
    -webkit-appearance: none;
    family: font(body);
    width: 100%;
  }

  input.button {
    @include truncate();
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    margin: {
      bottom: 10px;
    }
    padding: {
      right: 20px;
      left: 20px;
    }
    text-align: center;
    font: {
      size: 15px;
      family: font(body);
      weight: bold;
    }
    background: {
      color: color(green-400);
    }
    transition: all 0.4s cubic-bezier(0, 0.995, 0.41, 0.995);
    cursor: pointer;
    color: #fff;
  }

  .description {
    font-size: 12px;
    padding: 10px 0;
    display: block;
  }

  .form-item {
    padding-bottom: 30px;
  }
}

#magic-cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index: 1000000;
}

#ball {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border: 1px solid color(black);
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
}

.dark {
  #ball {
    border-color: #fff;
  }
}

.icon-wrap {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}
#ball.bl {
  transform: scale(2) translate(-9px, -9px);
  border: 1px solid #fff;
  left: 0;
}
