@charset "UTF-8";
section#visie {
  .text {
    padding: 30px 5%;

    img {
      margin-top: 20px;
    }
  }

  .images {
    display: none;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 2px;
    padding-left: 0;
    margin-top: 50px;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .progress {
    margin-left: -7.5px;
    margin-top: 50px;
    position: relative;
    width: 90px;
    font-size: 0;
    display: none;

    &:before {
      position: absolute;
      display: block;
      content: "";
      height: 2px;
      width: 15px;
      top: 0;
      background-color: rgba(0, 0, 0, 1);
      transition: left 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
    }
    span {
      color: rgba(0, 0, 0, 0.5);
      padding: 10px 0;
      width: 30px;
      text-align: center;
      display: inline-block;
      font-size: 0.8rem;
    }
  }
}

.visie-1 .progress {
  &:before {
    background: rgba(0, 0, 0, 1);
    left: calc(16.66% - 7.5px);
  }
}

.visie-2 .progress {
  &:before {
    background: rgba(0, 0, 0, 1);
    left: calc(50% - 7.5px);
  }
}

.visie-3 .progress {
  &:before {
    background: rgba(0, 0, 0, 1);
    left: calc(66.66% + 7.5px);
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  section#visie {
    position: relative;
    h2 {
      padding-left: 0;
      //margin-top: 150px;
      margin-top:0;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
    .text-container {
      width: 100%;

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 5% 0 10%;
        height: 100%;
        transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0s;
      }
      .image {
        width: 50%;
        padding: 0 5%;
        img {
          display:block;
        }
      }
    }
    .images {
      //width: 100vw;
      position: relative;
      //right: 0;
      //z-index: -1;
      //left: auto;
      //display: block;
      .image {
        //width: 100vw;
        //height: 100vh;
        //display: flex;
        //justify-content: flex-end;
        //align-items: center;
        padding: 0 5%;
        img {
          display: block;
          height: auto;
          width: 50%;
        }
      }
    }
  }
  .visie {
    header {
      //margin-bottom: 130vh;
    }
    section#visie {
      .text-container {
        //position: fixed;
        //top: 0;
        //left: 0;
        //width: 50vw;
      }
    }
  }

  /*.visie-1 {
    .text-1 {
      opacity: 1 !important;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0.3s!important;
    }
    .text-2,
    .text-3 {
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0s!important;
    }
  }

  .visie-2 {
    .text-2 {
      opacity: 1 !important;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0.3s!important;
    }
    .text-1,
    .text-3 {
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0s!important;
    }
  }

  .visie-3 {
    .text-3 {
      opacity: 1 !important;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0.3s!important;
    }
    .text-1,
    .text-2 {
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0s!important;
    }
  }

  .visie-4 {
    section#visie h2 {
      opacity:0;
    }
    .text-1,
    .text-2,
    .text-3 {
      transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995) 0s!important;
    }
  }*/
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
}
