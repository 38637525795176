@charset "UTF-8";

@keyframes productherofadeinperiscope {
  0% {
    opacity: 0;
    filter: blur(30px);
  }
  100% {
    opacity: 100%;
    filter: blur(0);
  }
}
