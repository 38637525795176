@charset "UTF-8";

/*
  Fieldsets
 */

fieldset {
  &:focus-within {
    // ...
  }
  legend {
    // ...
  }
}

label {
  family: font(body);
  font-size: 15px;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
  display: block;
}

/*
  All textual inputs
 */
textarea,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="reset"]):not([type="file"]) {
  -webkit-appearance: none;
  background-color: #efefef;
  padding: 26px 30px;
  display: block;
  border: none;
  family: font(body);
  width: 100%;
  letter-spacing: 0.5px;
  font-size: 13px;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin-bottom: 20px;

  &.required-error {
    border-bottom: 1px solid #e4002b;
  }
}

select {
  -webkit-appearance: none;
  background-color: #efefef;
  border-radius: 0;
  padding: 21px 20px;
  display: block;
  letter-spacing: 0.5px;
  background-image: url("../svg/icons/arrowdown.svg");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: 95% 50%;
  border: none;
  border-bottom: 1px solid #cccccc;
  family: font(body);
  width: 100%;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin-bottom: 20px;

  &.required-error {
    border-bottom: 1px solid #e4002b;
  }
}

/*
  Radiobuttons and checkboxes
 */

input[type="radio"] {
  &:checked {
    // ...
  }
  &[disabled] {
    // ...
  }
  // ...
}

input[type="checkbox"] {
  &:checked {
    // ...
  }
  &[disabled] {
    // ...
  }
  // ...
}

/*
  Buttons
 */

.ak-button {
  @include truncate();
  border: none;
  position: relative;
  display: inline-block;
  z-index: 2;
  margin-top: 2rem;
  padding: 0.7rem 0.8rem;
  border-radius: 2rem;
  border-color: transparent;
  border-width: 0.1rem;
  border-style: solid;
  text-align: center;
  font: {
    size: 1rem;
    family: font(body);
    weight: 500;
  }
  color: #fff;
  letter-spacing: 0.5px;
  background: {
    color: color(dark);
  }
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;

  &:hover {
    background {
      color: color(primary);
    }
  }

  &.outline {
    border-color: color(primary);
    background-color: transparent;
    color: color(white);
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      display: block;
      height: 100%;
      left: 0;
      width: 0%;
      background-color: color(dark-100);
      transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    &:hover {
      color: #fff;
      background: transparent;
      &:before {
        width: 100%;
      }
    }
  }

  &.primary {
    background-color: color(primary);
    color: #fff;
    border-color: color(primary);

    &:hover {
      background-color: transparent;
    }

    &.outline {
      border-color: color(primary);
      background-color: transparent;
      color: color(primary);

      &:before {
        background-color: color(primary);
      }

      &:hover {
        color: #fff;
        background-color: transparent;
        border-style: solid;
        &:before {
          width: 100%;
        }
      }
    }
  }

  &.primary-dark {
    background-color: color(green-400);
    color: #fff;

    &:hover {
      background-color: color(primary);
    }

    &.outline {
      border: 2px solid color(green-400);
      background-color: transparent;
      color: color(green-400);

      &:before {
        background-color: color(green-400);
      }

      &:hover {
        color: #fff;
        background-color: transparent;
        &:before {
          width: 100%;
        }
      }
    }
  }
  &.small {
    padding: 0.5rem 0.7rem;
    border-radius: 2rem;
    border-width: 0.1rem;

    font: {
      size: 0.7rem;
    }
  }
}

@include breakpoint(tablet-landscape) {
  .ak-button {
  }
}
