@charset "UTF-8";
section#aanpak {
  padding: 50px 0;
  .intro {
    text-align: center;
    padding: 0 5% 50px 5%;
    p {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1;
      letter-spacing: 2px;
      padding-left: 0;
      margin-top: 50px;
      display: block;
      margin-bottom: 50px;
      text-transform: uppercase;
      opacity: 0.5;
    }
  }
  .swiper-wrapper-outside {
    position: relative;
    width: 100%;
  }
  .swiper-container {
    width: 100%;
    height: auto;
    padding-top: 60px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: auto;
  }
  .swiper-pagination-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    margin: 0;
    z-index: 7;
    overflow-x: scroll;
    height: 50px;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    .swiper-pagination {
      width: calc(650px + 10vw);
      padding: 0 5vw;
      text-align: left;
      font-size: 0;
    }
  }
  .swiper-pagination-bullet {
    padding: 0 15px;
    box-sizing: border-box;
    min-width: 130px;
    max-width: 130px;
    overflow: hidden;
    border-radius: 0;
    width: auto;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    font-size: 0.6rem;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
    background: rgba(0, 0, 0, 0);
    &:hover {
      background: rgba(241, 241, 241, 1);
    }
  }
  .swiper-pagination-bullet-active {
    color: #fff;
    background: color(primary);
    &:hover {
      color: #fff;
      background: color(primary);
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .aanpak-item {
    text-align: left;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height:100%;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      p {
        font-size: 0.8rem;
      }
      h3 {
        font-size: 1.1rem;
      }
      h4 {
        text-transform: uppercase;
        opacity: 0.5;
        letter-spacing: 1px;
        font-size: 0.7rem;
        margin-bottom: 20px;
        padding-top: 20px;
        display: block;
      }
      p.green-strong {
        font-weight: bold;
        color: color(primary);
      }
    }
    .image {
      width: 100%;
      height: 300px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  section#aanpak {
    padding: 100px 0;
    .intro {
      padding: 0 5% 100px 5%;
      .heading {
        margin-bottom: 100px;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      right: 0;
      left: auto;
      height: calc(100% - 100px);
      width: 10vw;
      top: auto;
      bottom: 0;
      transform: none;
      display: block;
      &:after {
        display: none;
      }
    }

    .swiper-button-prev {
      right: auto;
      left: 0;
    }
    .swiper-container {
      padding-top: 100px;
    }
    .swiper-pagination-wrapper {
      position: absolute;
      top: 0;
      width: 100vw;
      background: rgba(255, 255, 255, 1);
      //max-width: calc(690px + 10vw);
      height: 82px;
      left: 0;
      //transform: translateX(-50%);
      margin: 0;
      z-index: 8;
      overflow-x: visible;
      height: 82px;
      text-align: center;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
      .swiper-pagination {
        width: calc(850px + 10vw);
        padding: 14px 5vw;
        box-sizing: border-box;
        position: static;
        text-align: center;
        font-size: 0;
        display: inline-block;

        .swiper-pagination-bullet {
          padding: 0 20px;

          min-width: 170px;
          border-radius: 0;

          height: 50px;
          border-radius: 50px;

          line-height: 50px;

          font-size: 0.6rem;

          transition: all 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
        }
      }
    }
    .swiper-slide {
      justify-content: center;
    }
    .aanpak-item {
      flex-direction: row;
      .text {
        width: 60%;
        padding: 50px;
      }
      .image {
        width: 40%;
        position: relative;
        height: 100%;
        img {
          display: block;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .aanpak {
    section#aanpak {
      .swiper-pagination-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
      }
    }
  }
  .aanpak-hide {
    section#aanpak {
      .swiper-pagination-wrapper {
        position: fixed;
        left: 0;
        transform: translate3d(0, -100%, 0);
        transition: transform 0.3s cubic-bezier(0, 0.995, 0.41, 0.995);
      }
    }
  }
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
}
