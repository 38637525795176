@charset "UTF-8";

.wrapper {
  //padding: 0 20px;
  height: 100%;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 2rem;
  .col-25,
  .col-33,
  .col-50,
  .col-75,
  .col-1,
  .col-2,
  .col-3 {
    width: 100%;
  }
}

.grid {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  .container {
    height: 100%;

    .col-1 {
      width: calc((100% - 40px) / 3);
    }
  }

  .col-1 {
    border-left: 1px solid color(black-10);
    border-right: 1px solid color(black-10);
    height: 100%;
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  .wrapper {
    //padding: 0 80px;
    height: 100%;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .col-25 {
      width: calc(25% - 1rem);
    }

    .col-33 {
      width: calc(33.33% - 1rem);
    }

    .col-50 {
      width: calc(50% - 1rem);
    }

    .col-75 {
      width: calc(75% - 1rem);
      .max-50-in-75 {
        max-width: 60%;
      }
  
    }

    .col-1 {
      width: calc((100% - 80px) / 3);
      &.wide {
        width: calc(((100% - 80px) / 3) + 40px)
      }
    }

    .col-2 {
      width: calc(((100% - 80px) * (2/3)) + 40px)
    }

    .col-3 {
      width: 100%;
    }
  }

  .grid {
    .container {
      .col-1 {
        width: calc((100% - 80px) / 3);
      }
    }
  }
}

@include breakpoint(desktop) {
  .wrapper {
    padding:0;
  }
  .container {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .col-1 {
      width: calc((100% - 160px) / 3);
      &.wide {
        width: calc(((100% - 160px) / 3) + 80px)
      }
    }

    .col-2 {
      width: calc(((100% - 160px) * (2/3)) + 80px)
      
    }

    .col-3 {
      width: 100%;
    }
  }

  .grid {
    .container {
      .col-1 {
        width: calc((100% - 160px) / 3);
      }
    }
  }
}

@include breakpoint(cinemadisplay) {
}
