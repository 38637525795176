@charset "UTF-8";
section#over {
  background: linear-gradient(180deg, rgba(238, 239, 243, 0.5) 0%, rgba(237, 230, 222, 0.5) 100%);

  .text {
    padding: 30px 5%;

    img {
      margin-top: 20px;
    }
  }

  .images {
    display: none;
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 2px;
    padding-left: 0;
    margin-top: 30px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-bottom: 50px;
    display: block;
  }
}

@include breakpoint(tablet-portrait) {
}

@include breakpoint(tablet-landscape) {
  section#over {
    display: flex;
    padding-top: 70px;
    .heading {
      margin-top: 0;
      padding-bottom: 70px;
    }
    .text {
      width: 50%;
      padding-top: 0;
      padding-bottom:100px;
    }
    .image {
      padding-left: 5%;
      min-height: 100%;

      img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
        transform: translateY(0);
      }
      width: 50%;
    }
  }
}

@include breakpoint(desktop) {
}

@include breakpoint(cinemadisplay) {
}
