@charset "UTF-8";

@keyframes productherofadein {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(.75);
    filter: blur(30px) contrast(200%) brightness(150%);
  }
  100% {
    opacity: 100%;
    transform: translateX(-50%) translateY(-50%) scale(1);
    filter: blur(0);
  }
}
