@charset "UTF-8";

footer {
  width: 100%;
  margin-top: 30px;
  padding: 50px 0;
  text-align: center;

  .mobile {
    display: inline-block;
    width: 58px;
    margin-bottom: 30px;
    opacity: 0.2;
  }

  .desktop {
    display: none;
  }

  p {
    opacity: 0.2;
  }

  .container {
    &:first-child {
      .col-1 {
        p {
          font-size: 13px;
        }
      }
      .col-2 {
        display: flex;

        ul.menu {
          width: 50%;
          flex-shrink: 0;
          flex-grow: 0;
          text-align: center;

          li {
            a {
              color: color(black-100);
              font-weight: bold;
              padding: 7.5px 0;
              display: block;
            }
          }
        }
      }
    }

    &:last-child {
      .col-2 {
        display: flex;
        flex-direction: column-reverse;

        .social {
          width: 100%;
          padding-bottom: 50px;
          padding-top: 50px;
          flex-grow: 0;
          flex-shrink: 0;
          ul.menu {
            width: 100%;

            li {
              display: inline-block;
              padding: 0 7.5px;
              a {
                width: 30px;
                height: 30px;
                text-indent: -9999px;
                display: block;
                background-size: contain;
                background-repeat: no-repeat;

                &.facebook {
                  background-image: url("../svg/facebook.svg");
                }
                &.twitter {
                  background-image: url("../svg/twitter.svg");
                }
                &.linkedin {
                  background-image: url("../svg/linkedin.svg");
                }
              }
            }
          }
        }

        .legal {
          width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
          padding-top: 30px;
          ul.menu {
            width: 100%;
            flex-shrink: 0;
            flex-grow: 0;
            text-align: center;

            li {
              display: inline-block;
              a {
                color: color(black-100);
                font-weight: normal;
                padding: 7.5px;
                font-size: 13px;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-landscape) {
  .newsletter {
    margin-bottom: -140px;
    margin-top: 100px;

    .container {
      align-items: center;
    }
    .image {
    }
    .news-form {
      padding-left: 50px;
      padding-top: 0;
    }
  }
  footer {
    padding: 130px 0 100px 0;
    margin-top: 80px;
    text-align: left;
    .container {
      &:first-child {
        .col-1 {
          text-align: right;
        }
        .col-2 {
          ul.menu {
            width: 40%;
            text-align: left;
          }
        }
      }

      &:last-child {
        .col-1 {
          padding-top: 80px;
          text-align: right;
          p {
            font-size: 13px;
          }
        }
        .col-2 {
          flex-direction: row;
          .social {
            width: 40%;
            padding-top: 80px;

            ul.menu {
              text-align: left;

              li {
                &:first-child {
                  padding-left: 0;

                  a {
                  }
                }
              }
            }
          }
          .legal {
            width: 60%;
            padding-top: 80px;

            ul.menu {
              text-align: left;
              li {
                &:first-child {
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: inline-block;
      width: 58px;
    }
  }
}

@include breakpoint(desktop) {
  .newsletter {
    margin-top: 170px;
  }
}
