@charset "UTF-8";

.breadcrumbs-main {
  display: none;
}

@include breakpoint(tablet-portrait) {
  .breadcrumbs-main {
    display:block;
    margin-bottom: 70px;
  }
  .page,
  .project,
  .expertise {
    .breadcrumbs-main {
      display: block;
      li {
        position: relative;
        display: inline-block;
        color: color(black);
        //&:nth-child(even) {
        //  padding: 0 10px;
        //}
        &:before {
          content: "";
          width: 3px;
          height:3px;
          display:inline-block;
          border-radius:50%;
          background-color:color(black);
          vertical-align: middle;
          margin-left:10px;
          margin-right:10px;
        }
        &:nth-child(1) {
          &:before {
            content: "";
            display:none;
          }
        }
        a {
          color: color(black);
          transition: all 0.25s ease-out;
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 1px;
          &:hover {
            color: color(black);
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-landscape) {
  .breadcrumbs-main {
    //top: 30px;
  }
}

@include breakpoint(desktop) {
  .breadcrumbs-main {
    //top: 50px;
  }
}
